.modal-header.small {
    position: relative;
    display: block;
    min-height: 50px;
    max-height: 50px;
    background: #2C2D2D;
    color: white;
    text-align: left;
    padding-top: 6px;
    padding-left: 35px;
}