@font-face {
    font-family: 'SourceSansProSemibold';
    src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot');
    src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot') format('embedded-opentype'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.woff') format('woff'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.ttf') format('truetype'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.svg#SourceSansProSemibold') format('svg');
}

$title-font: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;

#save-modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(79, 79, 79, 0.9);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#save-modal-container button {
    //display: flex;
    //justify-content: center;
    //align-items: center;
}

#save-modal-container button:disabled {
    opacity: 0.63;
    pointer-events: none;
}

.modal-wrapper {
    max-width: 800px;
    max-height: 550px;
    width: calc(100% - 100px);
    height: calc(100% - 30px);
    //border: 4px solid hsla(0, 100%, 100%, 0.25);
    border: 4px solid #2C2D2D;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
    
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    &-expanded {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.modal-header {
    position: relative;
    display: block;
    min-height: 90px;
    max-height: 90px;
    background: #2C2D2D;
    color: white;
    text-align: left;
    padding-top: 6px;
    padding-left: 35px;
}

.modal-header h4 {
    font-family: $title-font;
    font-size: 20px;
    margin-top: 0;
    line-height: 50px;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    text-align: left;
    padding-left: 35px;
}

.modal-header .icon-active {
    width:23px;
    height:23px;
    margin: 30px auto;
    text-align: center;
    background-color: #4389FF;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='-6 -5 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23141e25;%7D%3C/style%3E%3C/defs%3E%3Cpath d='M19.68,2,3.4,18.28,2,26l7.72-1.4L26,8.32Zm4,6.32-13.95,14-4-4,14-13.95ZM4.82,19.7,8.3,23.18,4.05,24Z'/%3E%3C/svg%3E");
    display: inline-block;
}

.modal-header .icon {
    width:23px;
    height:23px;
    margin: 30px auto;
    text-align: center;
    background: #444444;
    border-radius: 50%;
    display: inline-block;
    color: #A8B2B7;
}

.modal-header .text-description {
    color: #939393;
    font-size: 14px;
    margin: 10px;
    display: inline-block;
}

.modal-header .text-description-active {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    margin: 10px;
    display: inline-block;
}

.modal-header .straight-line {
    color: #444444;
    font-size: 14px;
    margin-right: 10px;
    display: inline-block;
}

.modal-body {
    // border-top: 1px solid #444444;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.keywords-div, .description-div {
    width: 100%;
}

#tagsArea{
    flex-grow: 1;
    padding: 15px;
    background: #2C2D2D;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
    color: white;
    border-top: 1px solid #444444;

    .is-invalid~.invalid-feedback {
        display: block;
    }
}

#tagsArea p {
    font-size: 1.1em;
    margin: 10px 0;
}

#tagsArea .footnote {
    font-size: 0.8em;
}

#tagsArea input:not(.react-tagsinput-input),
#tagsArea textarea {
    font-size: 16px;
    font-family: inherit;
    padding: 6px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
}

#tagsArea .react-tagsinput {
    width: 100%;
    height: 76px;
    box-sizing: border-box;
    border: 1px solid #808080;
    border-radius: 4px;
    padding: 6px;
    padding-top: 4px;
    padding-bottom: 0;
    font-family: inherit;
    overflow: auto;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #2C2D2D;

    &--focused {
        //border: 1px solid #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
}

#tagsArea .react-tagsinput-input {
    font-size: 16px;
    font-family: inherit;
    margin-bottom: 0;
    padding: 0;
    width: auto;
    white-space: nowrap;
    color:white;
}

#tagsArea .react-tagsinput-tag {
    background-color: #464646;
    border-radius: 2px;
    border: #E4E7E9;
    color: white;
    display: inline-block;
    font-family: inherit;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}

#tagsArea .react-tagsinput-remove {
    border-left: 1px solid gray;
    margin-left: 4px;
    color: white;

    &:hover,
    &:focus {
        color: white;
        text-decoration: none;
    }
}

#tagsArea #block-description {
    height: 88px;
    background-color: #2C2D2D;
    color: white;
    border: 1px solid gray;
}

#tagsArea .col-md-6 {
    &:first-of-type {
        padding-left: 0;
    }
    &:last-of-type {
        padding-right: 0;
    }
}

#tagsArea .row {
    padding-left: 5px;
    padding-right: 5px;

    &:first-of-type {
        margin-bottom: 10px;
    }
}

#tagsArea #libraries-dropdown {
    width: 100%;
    height: 40px;
    z-index: 10;
    outline: none;

    &.is-invalid .inner__control {
        border: 1px solid #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        .inner__dropdown-indicator {
            visibility: hidden;
        }
    }
}

#tagsArea #libraries-dropdown .inner__control {
    /*height: 40px;*/
    border: 1px solid gray;
    border-radius: 4px;
    background-color: #2C2D2D;
    color: white;


    &--is-focused {
        color: white;
        background-color: #2C2D2D;
        //border: 1px solid #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &--menu-is-open{
        box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
    }
}

#tagsArea #libraries-dropdown .inner__value-container,
#tagsArea #libraries-dropdown .inner__indicators {
    height: 38px;
}

#tagsArea #libraries-dropdown .inner__placeholder {
    margin-top: 5px;
    line-height: 1em;
    top: 35%;
    font-size: 14px;
}

#tagsArea #libraries-dropdown .inner__single-value {
    position: relative;
    height: calc(100% - 6px);
    color: white;
}

#tagsArea #libraries-dropdown .inner__input {
    /*height: 38px;*/
    color: white;
}

#tagsArea #libraries-dropdown .inner__menu {
    /*height: 38px;*/
    color: white;
}

#tagsArea #libraries-dropdown .inner__input #react-select-2-input {
    /*height: 38px;*/
}

/* Save to library inner menu */
#tagsArea .css-1n7v3ny-option {
    background-color: grey;
}

#tagsArea .css-26l3qy-menu {
    background-color: #2C2D2D;
    border: 1px solid #808080;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* End */


#tagsArea textarea {
    font-family: inherit;
    height: 60px;
}

#tagsArea select {
    font-family: inherit;
    font-size: 1em;
}

#tagsArea #block-name-div {
    height: 60px;
    margin-bottom: auto;

}

#tagsArea #block-name {
    height: 40px;
    background-color: #2C2D2D;
    border-color: #808080;
    border-width: 1px;
    color: white;

    &.is-invalid {
        border: 1px solid #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        //background-repeat: no-repeat;
        //background-position: right calc(0.375em + 0.1875rem) center;
        //background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        //transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

#tagsArea #save-to-library {
    height: 60px;
}

.modal-body #saveBlockWorkspace {
    height: 100%;
    flex-grow: 1;
}

.modal-body #saveBlockParams {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 150px;
    height: 100%;
}

.save-button-flex-container {
    flex: 1 1 0px;
    // border: 1px solid #444444;
    border-top: 1px solid #444444;
    margin-top: -1px;
    margin-left: 0;
    margin-right: 0;
    font-size: 1.1em;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    background-color: #2C2D2D;
    color: white;


    img {
        width: 60px;
    }
}

.save-button-flex-container:hover {
    background-color: #939393;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.save-button-flex-container:active {
    //border: 1px solid #4b96ff;
    border: 1px solid #464646;
    background-color: #e9ebee;
    -webkit-user-select: none;
    user-select: none;
}

.save-button-flex-container-content {
    position: relative;
    top: 60%;
    transform: translateY(-60%);
}

.save-button-flex-container-content > .save-button-bold {
    //font-weight: bold;
    font-size: 14px;
    line-height: 1.3em;
}

.save-button-flex-container-content > p {
    margin-top: 0;
    margin-bottom: 0;
}

.save-button-flex-container-content > img {
    margin-bottom: 5px;
    border-radius: 7px;
}

#blocklyArea{
    height: 0;
    // border-top: 1px solid #444444;
}

#scriptArea {
    height: 0;
    background: white;

    .monaco-editor {
        height: 100% !important;
        width: 100% !important;
    }
}

.modal-header {
    h3 {
        color: white;
        float: left;
        margin: 0;
        padding: 0;
    }

    .expand-icon-container {
        z-index: 10000;
        position: relative;
        float: right;
        margin-top: 4px;
        margin-right: 12px;
        padding: 3px;
        display: block;
        cursor: pointer;
    }
}

.modal-footer {
    background: #2C2D2D;
    min-height: 60px;
    padding: 0;
    //border-top: 1px solid #808080;
}

.modal-footer .modal-buttons-container {
    background: #2C2D2D;
}

.modal-footer .modal-buttons-container .text-button {
    background-color: #4389FF;
}

.modal-footer .modal-buttons-container .text-button-cancel {
    background-color: rgba(255, 255, 255, 0);
    width: 84px;
    color: #C6C6C6;
}


.close-modal-btn {
    color: white;
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin: 0;
}

.close-modal-btn:hover {
    color: black;
}

.btn-cancel, .btn-continue , .btn-continue-stepThree, .btn-back{
    background: coral;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;
    border-radius: 0.25rem;
    text-transform: uppercase;
}

.back-drop {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
}

.open-modal-btn {
    margin: 15px;
    padding: 10px;
    font-weight: bold;
}

.keyword-tag {
    color: white;
    background-color: #054d7d;
    margin-right: 10px;
    padding: 5px 8px;
    border-radius: 10px;
    white-space: nowrap;
}

.keyword-tag > a {
    margin-left: 5px;
}

.keyword-tag > a:hover {
    cursor: pointer;
}

#tags-wrapper {
    margin-bottom: 1.5em;
    width: 750px;
    line-height: 2.5em;
    word-wrap: break-word
}

.delete-input-icon {
    filter: invert(27%) sepia(77%) saturate(10%) hue-rotate(345deg) brightness(95%) contrast(83%);
    height: 26px;
    width: 26px;
    padding-bottom: 25px;
    display: inline;
    cursor: pointer;
}

.delete-input-icon-container {
    position: absolute;
}

.delete-input-icon-container:hover .delete-input-icon-link:hover {
    cursor: pointer;
}

@media screen and (max-height: 560px) {
    .save-button-flex-container img {
        height: 25px;
        width: 32px;
    }

    #tagsArea .react-tagsinput {
        height: 76px;
    }

    #tagsArea #block-description {
        height: 76px;
    }

    .keywords-div {
        //width: 50%;
        //float: left;
        //padding-right: 15px;
        width: 100%;
    }

    .description-div {
        //width: 50%;
        //float: right;
        //padding-left: 15px;
        width: 100%;
    }

    @media (max-width: 768px) {
        .keywords-div, .description-div {
            width: 100%;
            padding: 0;
        }
    }
}

@media screen and (max-height: 450px) {
    .save-button-flex-container-content {
        vertical-align: middle;
    }

    .save-button-flex-container-content > .save-button-bold {
        font-size: 11px;
    }
}

@media (max-width: 768px) {
    #tagsArea .col-md-6 {
        padding-left: 0;
        padding-right: 0;
    }

    #tagsArea .react-tagsinput {
        height: 76px;
    }

    #tagsArea #block-description {
        height: 64px;
    }

    #tagsArea .react-tagsinput-input {
        width: 80px;
    }

    #blocklyArea {
        display: flex;
        flex-direction: column;

        #saveBlockParams {
            height: 120px;
            width: 100%;
            bottom: 0;
            display: flex;
            flex-direction: row;

            .save-button-flex-container {
                flex: 1 1 0px;
                width: 100%;
            }
        }

        #saveBlockWorkspace {
            width: 100%;
            flex-grow: 1;
        }

        @media screen and (max-height: 560px) {
            #saveBlockParams {
                height: 80px;
            }

            #saveBlockParams p {
                font-size: 12px;
            }

            .keywords-div, .description-div {
                width: 100%;
                padding: 0;
            }
        }
    }
}

.toast {
    left: calc(50% - 175px);
    background-color: white !important;
}

#undoRedo {
    position: absolute;
    z-index: 10000;

    p {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .undoRedoIcon {
        width: 16px;
        height: 16px;
        color: #5B6670;
        cursor: pointer;
    }
}