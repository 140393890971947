.action-buttons-container {
  .icon {
    border-bottom: 1px solid #FFF;
    height: 12px;
    width: 12px;
    position: absolute;
    backface-visibility: hidden;
    perspective: 1000px;
    transform-origin: center;
    transition: transform 200ms cubic-bezier(0.175, 0.885, 0.145, 1.32);
    transform: scale(0.8) translateZ(0);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    &:after {
      background-color: #5B6670;
      content: '';
      height: 25px;
      width: 25px;
      position: absolute;
    }

    &-save {
      right: 31px;
      bottom: 206px;
      padding-bottom: 19px;
      &:after {
        mask: url("/images/save-icon.svg") no-repeat center;
        background-color: #A2A2A2;
      }
      &.is-hovered {
        &:after {
          background-color: red;
          mask: url("/images/save-icon.svg") no-repeat center;
        }
      }
    }
  }

  .undoIcon {
    position: absolute;
    right: 39px;
    color: grey;
    cursor: pointer;
  }

  .redoIcon {
    position: absolute;
    right: 39px;
    color: grey;
    cursor: pointer;
  }
}