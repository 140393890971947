@font-face {
    font-family: 'SourceSansProSemibold';
    src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot');
    src: url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.eot') format('embedded-opentype'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.woff') format('woff'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.ttf') format('truetype'),
    url('../../assets/fonts/source-sans-pro/sourcesanspro-semibold.svg#SourceSansProSemibold') format('svg');
}

#save-rest-block-error-dialog-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(4,77,124,0.6);
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#save-rest-block-error-dialog-wrapper {
    width: 550px;
    height: 230px;
    border: 4px solid #2C2D2D;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
}

.save-rest-block-error-dialog-header {
    height: 3.125rem;
    background: #2C2D2D;
    color: white;
    text-align: center;

    h4 {
        margin: 0;
        line-height: 50px;
        font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
        text-transform: uppercase;
    }
}

.save-rest-block-error-dialog-body {
    flex-grow: 1;
    justify-content: space-between;
    background: #2C2D2D;
    padding: 20px;
    font-size: 16px;
    color: white;
    border-top: 1px solid #444444;
}

.save-rest-block-error-dialog-footer {
    background: #2C2D2D;
    min-height: 60px;
}

.save-rest-block-error-dialog-footer .btn-OK {
    background-color: #4389FF;
    font-family: 'SourceSansProSemibold', Arial, 'Times New Roman', Verdana;
    border: none;
    color: white;
    cursor: pointer;
    outline: none;
    padding: 5px;
    margin: 12px 18px;
    border-radius: 0.25rem;
    float: right;
    text-transform: uppercase;
    font-size: 14px;
    width: 84px;
    height: 36px;
    letter-spacing: 0.25px;
}
